// import { PlanType } from "../components/pages/VehicleInsurenceComponent/AllLeadsComponent/addJsonData";

export const APIUrlStrings = {
  getQuotation: "/quatation/getQuatation",
  // login: "/user/login",
  // logout: "/logout",
  // productList: "/products/getAll",
  // rolelist: "/roles/getAll",
  // userPermission: "/user-permission",
  // modulePermission: "/user/module-wise-permission",
  // createRole: "/role/create",
};

export const AuthURLStrings = {
  login: "/user/login",
  // logout: "/logout",
  logout: "user/logout",
  forgotPassword: "user/forgot-password",
  updatePassword: "user/update-password",
  getRoleList: "/roles/getAll",
  editRoleGetById: "role/getbyid",
  getUserList: "/user/filter",
  userGetById: "user/getById",
  getModuleAndPermissionByRole: "user/get_module_and_permissions_by_role",
  updatePermission: "user/update_role_permissions",

  getTeamLeader: "/myteams/getteamLeader",
  getAllTeam: "teams/getAllTeam",
  getRole: "/myteams/getRole",
  teamName: "myteams/teamName",
  getProduct: "/myteams/getProduct",
  createTeam: "myteams/createTeam",
  userCreate: "/user/create",
  dropdownReportingPerson: "/user/dropdown-reportingPerson",
  roleDropdownList: "role/dropdown-list",
  createRole: "role/create",
  deleteRole: "role/deleteRole",
  userDelete: "user/delete",
  deleteTeam: "myteams/deleteTeam",
  userUpdate: "user/update",
  roleUpdate: "role/update",
  teamUpdate: "myteams/updateTeam",
  countRoleUserTeam: "user/get_user_role_teams_count",
  telecallerListing: "user/get-telecaller-listing",
  modulesAndPermission: "user/get_module_and_permissions",

  // ------------change password-------
  changePass: "user/change-password",
  validateOtp: "user/validate-otp",
};

export const LeadURLStrings = {
  leadList: "/lead/getall",
  leadGetId: "/lead/getbyId",
  // leadList:"/lead/getAll",
  leadCreate: "/lead/create",
  leadUpdate: "lead/update",
  // editLeadDetails: "lead/edit_lead",
  editLeadDetails: "lead/editlead-detail",
  leadDelete: "/lead/delete",
  leadCount: "/lead/lead-count",
  leadAssign: "/lead/assign",

  //transferLead
  leadTransfer: "/lead/transfer",


  // ----Disposition via without call---
  interactionHistoryDropdown :"lead/disposition_interaction_dropdown",

  // data for mail and SMS
  dataForMailSMS: "/lead/get_lead_by_id",

  // Follow-up-lead tabel-list
  followUpTransferLeadList: "/leads/transfer-followup_list",
  // Follow-up-lead dropdown
  getfollowTransferDropdown: "/lead/telecaller-dropdown",

  // -----interaction history List' -------
  interactionHistoryList:"lead/interaction-history",

  // details Page For Admin
  leadDetailsForAdmin: "lead/lead-page-details",
  quotationDetailsForAdmin: "lead/quotation-details",
  proposalDetailsForAdmin: "lead/proposal-details",
  soldPolicyDetailsForAdmin: "lead/policy-details",

  // -----downloadPreviousPolicy----
  downloadPreviousPolicy: "lead/download_prev_policy",

  //  policyStatsData for dashboard
  // policyStatsData: "lead/lead_followUps_admin",

  // download leads
  leadDownload: "/lead/lead-download-listing",
  leadDetialsEdit: "lead/lead_details_for_edit",

  // lead details edit
  icDropdown: "lead/ic-dropdown",
  lastYearDiscount: "lead/lastyeardiscount-dropdown",
  manufacturerDropdown: "lead/get-manufacturer-dropdown",
  modelDropdown: "lead/get-model-dropdown",
  variantDropdown: "lead/get-variant-dropdown",
  rtoDropdown: "lead/rto_details",
  reqForAprove: "lead/update_request",

  // ---update lead info---------
  getReqListForUpdate: "lead/lead_update_requests_listing",
  getComparisonData: "lead/update_request/comparison",
  aproveUpdated: "lead/update_request",
  aproveAndReject: "/lead/update_request/approve",

  // ----------------Alternate Mobile no.-----------
  alternateNumber: "dialler/insert_alternate_number",

  // ------Quote data send to BE---
sendQuoteData:"lead/save-url-quote",

// ---------inbound call -------
inboundLeadMobNumber :"lead/get-mobileno",

  // ---------- follow up----------
  followLeadList: "lead/follow-up-lead",

  followUpCount: "lead/lead_followUp_count",

  adminFollowupData: "lead/lead_followUps_admin",

  leadEdit: "/lead/edit_lead",
  teamLeaderDropdown: "/lead/teamLeader-dropdown",
  telecallerDropdown: "/lead/telecaller-dropdown",
  policyTypeDropdown: "/lead/lead-policyType/dropDown",
  leadPlanTypeDropdown: "/lead/lead-planType/dropDown",
  leadTypeDropdown: "/lead/lead-type/dropdown",
  leadSourceDropdown: "/leadSource/dropdown",
  productDropdown: "lead/sub_product_dropdown",

  // ----------get Quotation-------
  getQuotation: "/quatation/getQuatation",
  getProposal: "proposal/getProposal",
  getIdv: "deviation/idv/get",
  getBreakin: "deviation/breakin/get",
  getDiscount: "deviation/discount/get",
  // master-campaign
  getMasterCampaignList: "campaign/getAll",
  createMasterCampaignAdd: "campaign/create",
  getByIdMasterCampaign: "campaign/getById",
  updateMasterCampaign: "campaign/update",
  deleteMasterCampaign: "campaign/delete",
  // master-source
  getMasterSourceList: "leadSource/getAll",
  getByIdMasterSource: "leadSource/getById",
  updateMasterSource: "leadsource/update",
  deleteMasterSource: "leadsource/delete",
  getByIdMasterSourceDropdownList: "publisher/getDropdownList",
  createMasterSourceAdd: "leadSource/Create",
  //master -publisher
  getMasterPublisherList: "publisher/getAll",
  createMasterPublisherAdd: "publisher/create",
  getByIdMasterPublisher: "publisher/getById",
  updateMasterPublisher: "publisher/update",
  deleteMasterPublisher: "publisher/delete",

  // getSoldPolicyList:"policies/getPolicies",
  getLeadDetails: "lead/leadDetails",
  getSoldPolicyList: "policies/getPolicies",
  getDMSList: "lead/dms-get-dmsListing",
  getCommonDetails: "leadProduct-common/details",
  DocumentDelete: "lead/dms-delete",
  dmsDetails: "lead/dms-details",
  // Lost Leads
  getLostLeadList: "lost-leads/getAll",
  getLostLeaadTelecaller: "lead/telecaller-dropdown",
  postLostLeadReAssign: "lead/assign",
  reAssignLostlead: "/lead/assign",

  getdocumentdropdownlist: "lead/dms-group-dropdown",
  getdocumentdropdowntype: "lead/dms-type-dropdown",
  createdocument: "lead/dms-create-upload",
  getDocumentUploadStatusList: "lead/dms-get-list",

  downloadSoldPolicy: "policies/getPolicies",
  downloadQuotation: "quatation/getQuatation",
  downloadPraposal: "proposal/getProposal",
  downloadIDV: "deviation/idv/get",
  downloadDiscount: "deviation/discount/get",
  downloadBreaking: "deviation/breakin/get",

  // ---------lead details ----

  getStatusUpdate: "lead/lead_progress",
  getDispositionLeadById: "lead/disposition",
  getDispositionLeadDetailsById: "lead/leadDisposition",
  getDispositionDropDown: "lead/disposition-dropdown",
  getSubDispositionDropdown: "/lead/sub_disposition-dropdown",
  getAuditorList: "scoredLead",
  getAuditDetails: "auditDetail",
  getCallRecordingDetails: "callRecordingDetail",
  getCallRating: "callRating",

  // bulk upload
  bulkUplodFile: "lead/bulk_Upload",
  formetDownload: "lead/sample_csv",
  downloadInvalidData: "lead/error_csv",
  invalidData: "/lead/preview_bulk_upload",
  materDataDictionary: "lead/download_ddc_csv",

  // fetch leads

  fetchLeads: "lead/renewal-notice-data",

  // telecaller
  telecallerLeadList: "lead/telecaller-lead-listing",
  LeadCountTelecallerWise: "lead/getLeadCountTelecallerWise",
  getQuotationDetails: "lead/get_quotation",
  createDisposition: "lead/add-disposition",
  leadDetailsForTelecaller: "lead/get_details_for_telecaller",
  dialler: "/dialler/make_manual_call",
  dropCall: "/dialler/drop_call",
  sendEmail: "lead/send_quotationmail_giib",
  sendSMS: "lead/send_sms_giib",
  // sendSMS: "lead/send_renewal_sms",
  quoteFromGiib: "lead/renewal-quote-from-giib",
  icList: "lead/get_active_ics_giib",

  // dashboard
  // Admin
  productleadList: "/dashboard/count_data",
  planList: "/dashboard/top_plans",
  leadStatistic: "lead/lead-statistics-dashboard",
  productPerformance: "lead/get-product-performance",
  leadSource: "lead/lead-source-dashboard",

  idvCount: "/deviation/idv/count",
  discountCount: "/deviation/discount/count",
  breakinCount: "/deviation/breakin/count",

  misReportDownload: "lead/generate_mis_report",
  misAllReportDownload:"lead/all_lead_mis_report",

  // Telecaller
  tellercallerCardData: "/lead/telecaller-dashboard-data",
  tellercallerdashboardCardData: "/lead/dashboard-lead-counts",
  actionableInsights: "/lead/actionable-insights-leads-listing",
  leadInformation: "lead/lead-report",

  topPerformance: "lead/top_performers",
  policyStatus: "lead/lead-policy-status",
  downloadLeadDoc: "lead/dms-file",
  PolicyStatsComponent: '/lead/policy_statistics',
  PolicyAnalysisComponent: '/lead/policy_analysis_status',
};
//   getLeadDetails:"lead/leadDetails",
//   getSoldPolicyList:"policies/getPolicies",
//   getDMSList:"lead/dms-get-dmsListing",
//   getCommonDetails:"leadProduct-common/details",
//   getLostLeadList:"lost-leads/getAll",
//   downloadSoldPolicy:"policies/getPolicies",
//   downloadQuotation:"quatation/getQuatation",
//   downloadPraposal:"proposal/getProposal",
//   downloadIDV:"deviation/idv/get",
//   downloadDiscount:"deviation/discount/get",
//   downloadBreaking:"deviation/breakin/get"
// }
