import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../../../shared/CustomTable";
import playicon from "../../../../../assets/icons/Vector.svg";
import pauseicon from "../../../../../assets/icons/Vector.svg";
import "./LeadManagmentCallStatus.scss";
import { BsXOctagonFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment";

// Modal.setAppElement("#root");

const LeadManagmentCallHistoryTableList = ({
  pagination,
  onPaginationHandler = () => {},
  tableRow = [],
  onDownload = () => {},
  tableLoaderShow = false,
}) => {
  const [tableRowDataList, setTableRowDataList] = useState([]);

  // -----------testing-------------
  const [audioUrl, setAudioUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);

  console.log("showAudioPlayer", showAudioPlayer);

  // const onPlay = useCallback((audioPath) => {
  //   console.log("audioPath", audioPath);
  //   setAudioUrl(audioPath);
  //   setIsModalOpen(true); // Open the modal with the audio player
  // }, []);

  const onClose = () => {
    setIsModalOpen(false);
    setAudioUrl("");
  };

  // const handlePlay = () => {
  //   if (audioPlayerRef.current) {
  //     audioPlayerRef.current.audioEl.current.play();
  //   }
  // };
  const handlePlay = (audioPath) => {
    if (audioPlayerRef.current) {
      const audioElement = audioPlayerRef.current;

      if (audioElement.paused) {
        audioElement.play();
        setIsPlaying(true);
      } else {
        audioElement.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleAudioPlay = () => {
    // const audio = new Audio(
    //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
    // );
    // audio.play();
    setShowAudioPlayer(true);
    console.log("play");
  };

  // const [tableRowData,setTableRowData] = useState()

  const actionOptionRender = useCallback(
    (row) => {
      return (
        <div
          style={{
            display: "flex",
            gap: "0",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="call-history-icon" style={{ position: "relative" }}>
            <button
              className="align-middle me-1 cursor-pointer button-icon"
              onClick={() => handlePlay(row?.call_recording)}
            >
              {" "}
            </button>
            {/* <img
              src={isPlaying ? pauseicon : playicon}
              style={{ height: "1rem" }}
              alt="Play/Pause Icon"
            /> */}
            {/* Audio player component */}
            <audio ref={audioPlayerRef} controls>
              <source src={row?.call_recording} />
              Your browser does not support the audio element.
            </audio>
          </div>
          <hr />
        </div>
      );
    },
    [isPlaying]
  );

  const statusOptionRender = useCallback((row) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          className=""
          style={{
            color:
              row?.callStatusid === 1
                ? "rgba(198, 33, 33, 1)"
                : row?.callStatusid === 0
                ? "rgba(188, 153, 61, 1)"
                : "rgba(22, 163, 74, 1)",
          }}
        >
          {row?.callStatus}
        </div>
      </div>
    );
  }, []);
  const callingRenderFun = useCallback((row) => {
    return (
      <div>
        {row.calling_date
          ? moment(row.calling_date, ["DD-MM-YYYY", "YYYY-MM-DD"]).format(
              "DD-MM-YYYY"
            )
          : "-"}{" "}
        {row.calling_time
          ? moment(row.calling_time, "hh:mm.ss A").format("hh:mm A")
          : " "}
      </div>
    );
  }, []);

  const followUpRenderFun = useCallback((row) => {
    return (
      <div>
        {row.next_follow_up_date && row.next_follow_up_time
          ? `${moment(row.next_follow_up_date, [
              "DD-MM-YYYY",
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY")} / ${moment(
              row.next_follow_up_time,
              "hh:mm.ss A"
            ).format("hh:mm A")}`
          : "---"}
        {/* {row.next_follow_up_date
          ? moment(row.next_follow_up_date, [
              "DD-MM-YYYY",
              "YYYY-MM-DD",
            ]).format("DD-MM-YYYY")
          : ("-")}
        /{" "}
        {row.next_follow_up_time
          ? moment(row.next_follow_up_time, "hh:mm.ss A").format("hh:mm A")
          : " "} */}
      </div>
    );
  }, []);

  const remarksRenderFun = useCallback((row) => {
    return (
      <div>
        <span
          className="custom-tooltip"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.remarks}
          style={{
            whiteSpace: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "50px", // Limit the width of the text to show ellipsis
            display: "d-inline-block", // Ensure ellipsis works on inline elements
            cursor: "pointer", // Make it look clickable
          }}
        >
          {row?.remarks?.substring(0, 10)}...{" "}
          {/* Show first 10 characters followed by ellipsis */}
        </span>
      </div>
    );
  }, []);
  const callDurationRenderFun = useCallback((row) => {
    return <div>{row.call_time_duration ? row.call_time_duration : "-"}</div>;
  }, []);

  const TableColumnDataList = [
    {
      id: 1,
      label: "CRE",
      rowId: "CRE",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 3,
      label: "Call Duration",
      rowId: "callDurationRender",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 4,
      label: "Call Disposition",
      rowId: "disposition_name",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 4,
      label: "Sub Disposition",
      rowId: "sub_disposition",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 5,
      label: "Call Date",
      rowId: "callingRender",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 6,
      label: "Next Follow Up",
      rowId: "followUpRender",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 7,
      label: "Remark",
      rowId: "remarksRender",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 7,
      label: "Recordings",
      rowId: "recordings",
      position: "center",
      isRenderItem: true,
    },
  ];

  useEffect(() => {
    const updatedTableRowDataList = tableRow.map((value) => ({
      ...value,
      recordings: actionOptionRender(value),
      callStatus: statusOptionRender(value),
      remarksRender: remarksRenderFun(value),
      callingRender: callingRenderFun(value),
      followUpRender: followUpRenderFun(value),
      callDurationRender: callDurationRenderFun(value),
    }));
    setTableRowDataList(updatedTableRowDataList);
  }, [
    actionOptionRender,
    callingRenderFun,
    followUpRenderFun,
    remarksRenderFun,
    statusOptionRender,
    tableRow,
    callDurationRenderFun,
  ]);

  return (
    <div>
      <CustomTable
        tableLoaderShow={tableLoaderShow}
        tableColumn={TableColumnDataList}
        tableRow={tableRowDataList}
      />
    </div>
  );
};

export default LeadManagmentCallHistoryTableList;
