const settings = {
    // AUTH_API_BASE_URL: "http://103.193.75.11:9090/qcrm/auth/v1", // production Auth
    // AUTH_API_BASE_URL: "http://45.120.137.128:9090/qcrm/auth/v1", //UAT Auth
    // AUTH_API_BASE_URL: "http://192.168.0.112:7778/qcrm/auth/v1", // Akshay
    // AUTH_API_BASE_URL: "http://192.168.0.119:4444/qcrm/auth/v1", // Pinki
    // AUTH_API_BASE_URL: "http://192.168.0.118:7844/qcrm/auth/v1", // Pallavi
    // AUTH_API_BASE_URL: "http://192.168.0.94:4444/qcrm/auth/v1", // Aamer
    // AUTH_API_BASE_URL: "http://192.168.0.64:6677/qcrm/auth/v1", // Ritik
    // AUTH_API_BASE_URL: "http://localhost:4445/qcrm/auth/v1", // me
    // AUTH_API_BASE_URL: "https://api.qcrm.quantique.ai/qcrm/auth/v1", // auth production
    AUTH_API_BASE_URL: "https://api-qcrm.quantique.ai/qcrm/auth/v1", // new auth production
    // L_BASE_URL: "http://103.193.75.11:9191/qcrm/leads/v1", // production Lead
    // LEAD_API_BASE_URL: "http://45.120.137.128:9191/qcrm/leads/v1", //UAT Lead
    // LEAD_API_BASE_URL: "http://192.168.0.118:7848/qcrm/leads/v1", // Pallavi
    // LEAD_API_BASE_URL: "http://192.168.0.112:7777/qcrm/leads/v1", // Akshay
    // LEAD_API_BASE_URL: "http://192.168.0.119:7777/qcrm/leads/v1", // Pinki
    // LEAD_API_BASE_URL: "http://192.168.0.94:7777/qcrm/leads/v1", // Aamer
    // LEAD_API_BASE_URL: "http://192.168.0.64:6777/qcrm/leads/v1", // Ritik
    // LEAD_API_BASE_URL: "http://localhost:7778/qcrm/leads/v1", // me
    // LEAD_API_BASE_URL: "https://api.qcrm.quantique.ai/qcrm/leads/v1", // lead production
    LEAD_API_BASE_URL: "https://api-qcrm.quantique.ai/qcrm/leads/v1", // new lead production
  };
  export default settings;