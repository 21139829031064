import authClient, { loginClient } from "./authClient";
import apiClient from "./client";
import privateAPIClient from "./privateClient";
import { APIUrlStrings, AuthURLStrings, LeadURLStrings } from "./endpoints";
import privateAPIClientMultiForm from "./privateClientMultiForm";
import settings from "./settings";
// import privateAPIClientMultiForm from "./privateClientMultiForm";

//login
// export const APIAdminLogin = (payload) => {
//   return authClient.post(AuthURLStrings.login, payload);
// };
export const APIAdminLogin = (payload) => {
  return loginClient.post(AuthURLStrings.login, payload);
};

// Change Pass
export const APIChangePass = (payload, userId) => {
  return authClient.post(AuthURLStrings.changePass + "/" + userId, payload);
};
export const APIValidateOtp = (payload) => {
  return authClient.post(AuthURLStrings.validateOtp, payload);
};

// Forgot Password
export const APIForgotPassword = (payload) => {
  return authClient.post(AuthURLStrings.forgotPassword, payload);
};
// Update Password
export const APIUpdatePassword = (payload) => {
  return authClient.post(AuthURLStrings.updatePassword, payload);
};

// Edit Lead Details
export const APIEditLeadDetails = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.leadDetialsEdit + "/" + payload?.leadId
  );
};
export const APIEditLeadInfo = (leadId, payload) => {
  return privateAPIClient.put(
    LeadURLStrings.editLeadDetails + "/" + leadId,
    payload
  );
};

// --downloadPreviosYearPolicy----
export const APIDownloadPreviousYearPolicy = (payload) => {
  return privateAPIClient.post(LeadURLStrings.downloadPreviousPolicy, payload);
};

// -------disposition via without call-----
export const APIgetInteractionOption = () => {
  return privateAPIClient.get(
    LeadURLStrings.interactionHistoryDropdown 
  );
};

// ------------interaction history List -------
export const APIgetInteractionHistory = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.interactionHistoryList +"/"+payload?.leadId
  );
};


// inbound Lead Mobile numebr---
export const APIInboundMobNumber =(paylaod)=>{
  return privateAPIClient.post(
    LeadURLStrings.inboundLeadMobNumber,paylaod
  )
}

// ------Data for SMS and mail------
export const APIgetDataSMSMail = (paylaod) => {
  return privateAPIClient.get(
    LeadURLStrings.dataForMailSMS + "/" + paylaod?.leadId
  );
};

export const APIgetICOption = () => {
  return privateAPIClient.get(LeadURLStrings.icDropdown);
};
export const APIgetLastYearDiscount = () => {
  return privateAPIClient.get(LeadURLStrings.lastYearDiscount);
};
export const APIgetManufaturerOption = () => {
  return privateAPIClient.get(LeadURLStrings.manufacturerDropdown);
};
export const APIgetModelOption = (payload) => {
  return privateAPIClient.get(LeadURLStrings.modelDropdown + "/" + payload?.id);
};
export const APIgetVariant = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.variantDropdown + "/" + payload?.id
  );
};
export const APIgetRto = () => {
  return privateAPIClient.get(LeadURLStrings.rtoDropdown);
};

// ------------lead update request-----
export const APIForAproveReq = (payload) => {
  return privateAPIClient.post(LeadURLStrings.reqForAprove, payload);
};

// ---------Quotation----------

export const APIgetQuotationList = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.getQuotation,
    payload
    // + "?per_page=" +
    // (payload?.perPage || 30) +
    // "&page=" +
    // (payload?.page || 0)
    // +
    // "&search_text=" +
    // (payload?.search_text || "")
  );
};

export const APIQuotationDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.downloadQuotation, payload);
};

export const APIgetProposalList = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.getProposal,
    payload
    // + "?per_page=" +
    // (payload?.perPage || 30) +
    // "&page=" +
    // (payload?.page || 0)
    // +
    // "&search_text=" +
    // (payload?.search_text || "")
  );
};

export const APIPraposalDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.downloadPraposal, payload);
};
export const APIidvList = (payload, id) => {
  return privateAPIClient.post(LeadURLStrings.getIdv + "/" + id, payload);
};

export const APIidvDownload = (payload, id) => {
  return privateAPIClient.post(LeadURLStrings.downloadIDV + "/" + id, payload);
};

export const APIDiscountList = (payload, id) => {
  return privateAPIClient.post(LeadURLStrings.getDiscount + "/" + id, payload);
};

export const APIDiscountDownload = (payload, id) => {
  return privateAPIClient.post(
    LeadURLStrings.downloadDiscount + "/" + id,
    payload
  );
};

export const APIBreakinList = (payload, id) => {
  return privateAPIClient.post(LeadURLStrings.getBreakin + "/" + id, payload);
};
export const APIBreakinLdownload = (payload, id) => {
  return privateAPIClient.post(
    LeadURLStrings.downloadBreaking + "/" + id,
    payload
  );
};
// Master Lead - Campaign
export const APIMasterCampaignList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getMasterCampaignList, payload);
};
export const APIMasterCampaignCreate = (payload) => {
  return privateAPIClient.post(LeadURLStrings.createMasterCampaignAdd, payload);
};
export const APIMasterCampaignId = (id) => {
  return privateAPIClient.get(`${LeadURLStrings.getByIdMasterCampaign}/${id}`);
};

export const APIMasterCampaignUpdate = (id, payload) => {
  return privateAPIClient.put(
    `${LeadURLStrings.updateMasterCampaign}/${id}`,
    payload
  );
};
export const APIMasterCampaignDelete = (id) => {
  return privateAPIClient.put(`${LeadURLStrings.deleteMasterCampaign}/${id}`);
};

// Master Lead - Publisher
// export const APIMasterLeadSources =(apimethod)=>{

// }
export const APIMasterPublisherList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getMasterPublisherList, payload);
};
export const APIMasterPublisherCreate = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.createMasterPublisherAdd,
    payload
  );
};

export const APIMasterPublisherUpdate = (id, payload) => {
  return privateAPIClient.put(
    `${LeadURLStrings.updateMasterPublisher}/${id}`,
    payload
  );
};
export const APIMasterPublisherGetbyID = (id) => {
  return privateAPIClient.get(`${LeadURLStrings.getByIdMasterPublisher}/${id}`);
};
export const APIMasterPublisherDelete = (id) => {
  return privateAPIClient.put(`${LeadURLStrings.deleteMasterPublisher}/${id}`);
};

// Master Lead - Source
export const APIMasterSourceList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getMasterSourceList, payload);
};
export const APIMasterSourceId = (id) => {
  return privateAPIClient.get(`${LeadURLStrings.getByIdMasterSource}/${id}`);
};
export const APIMasterSourceIdDropdown = (id) => {
  return privateAPIClient.get(
    `${LeadURLStrings.getByIdMasterSourceDropdownList}/${id}`
  );
};
export const APIMasterSourceCreate = (payload) => {
  return privateAPIClient.post(LeadURLStrings.createMasterSourceAdd, payload);
};
export const APIMasterSourceUpdate = (id, payload) => {
  return privateAPIClient.put(
    `${LeadURLStrings.updateMasterSource}/${id}`,
    payload
  );
  // return privateAPIClient.put(
  //   `${LeadURLStrings.updateMasterSource}/${id}`,
  //   payload
  // );
};
export const APIMasterSourceDelete = (id) => {
  return privateAPIClient.put(`${LeadURLStrings.deleteMasterSource}/${id}`);
};

export const APIMasterSourceGetbyID = (payload) => {
  return privateAPIClient.post(LeadURLStrings.createMasterSourceAdd, payload);
};

export const API = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.getQuotation + "/" + payload?.id,
    payload
  );
};

export const APISoldPolicyList = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.getSoldPolicyList,
    payload
    // +"/"+payload?.id, payload
  );
};

export const APIRoleList = (payload) => {
  return authClient.post(
    AuthURLStrings.getRoleList +
      "?page=" +
      payload?.page +
      "&pageSize=" +
      payload?.pageSize
  );
};
export const APIRoleEditById = (payload) => {
  return authClient.get(AuthURLStrings.editRoleGetById + "/" + payload?.id);
};

export const APIGetModuleAndPermissionsaByRole = (payload) => {
  return authClient.get(
    AuthURLStrings.getModuleAndPermissionByRole +
      "/" +
      payload?.productId +
      "/" +
      payload?.roleId
  );
};

export const APIModeulAndPermissionList = () => {
  return authClient.get(AuthURLStrings.modulesAndPermission);
};

export const APIUserList = (id, search, payload) => {
  return authClient.post(
    // `${AuthURLStrings.getUserList}?roleId=${id || ""}&search=${
    //   search || ""
    // }&pageSize=10&page=`
    `${AuthURLStrings.getUserList}?roleId=${id || ""}&search=${
      search || ""
    }&page=${payload?.page}&pageSize=${payload?.pageSize}`
  );
};

// -----------------not in use----------------
export const APIidvAprovedList = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.getQuotation
    // + "?per_page=" +
    // (payload?.perPage || 30) +
    // "&page=" +
    // (payload?.page || 0)
    // +
    // "&search_text=" +
    // (payload?.search_text || "")
  );
};

export const APIidvPrejectedList = (payload) => {
  return privateAPIClient.post(
    LeadURLStrings.getQuotation + "/" + payload?.id,
    payload
  );
};
// export const APIAdminProduct = () => {
//   return privateAPIClient.get(APIUrlStrings.productList);
// };

// export const APIAdminLogout = (payload) => {
//   return privateAPIClient.post(
//     APIUrlStrings.logout + "?email=" + payload?.emailid
//   );
// };

// export const APIgetUserRole = (payload) => {
//   return apiClient.post(APIUrlStrings.userPermission, payload);
// };

export const APILogout = (payload) => {
  return authClient.post(AuthURLStrings.logout, payload);
};
export const APIgetRoleList = (payload) => {
  return apiClient.post(APIUrlStrings.rolelist);
};

export const APIgetModuleWisePermission = (payload) => {
  return apiClient.get(APIUrlStrings.modulePermission + "/" + payload?.roleId);
};

export const APIgetLeadDetails = (id) => {
  return privateAPIClient.get(LeadURLStrings.getLeadDetails + "/" + id);
};

// ------------update Lead info------
export const APIgetReqListLeadInfo = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getReqListForUpdate, payload);
};
export const APIgetCopmareLeadInfo = (leadId) => {
  return privateAPIClient.post(LeadURLStrings.getComparisonData, leadId);
};
export const APIAproveRejectByAdmin = (leadId, payload) => {
  return privateAPIClient.post(LeadURLStrings.aproveAndReject, payload);
};

// ----------follow up---------
export const APIFollowUpLeadList = (paylaod) => {
  return privateAPIClient.post(LeadURLStrings.followLeadList, paylaod);
};

// --------- followup-tabel-list followUpLeadList
export const APIFollowUpLeadTabelList = (paylaod) => {
  return privateAPIClient.post(
    LeadURLStrings.followUpTransferLeadList,
    paylaod
  );
};

export const APIgetFollowupTransferDropdown = (id) => {
  return privateAPIClient.get(LeadURLStrings.getfollowTransferDropdown);
};

export const APIFollowUpCount = () => {
  return privateAPIClient.get(LeadURLStrings.followUpCount);
};

// Lead Details,Quotation,praposal and Sold policy
export const APIgetLeadDetailForAdmin = (leadId) => {
  return privateAPIClient.get(
    LeadURLStrings.leadDetailsForAdmin + "/" + leadId
  );
};
export const APIgetQuotationDetailForAdmin = (leadId) => {
  return privateAPIClient.get(
    LeadURLStrings.quotationDetailsForAdmin + "/" + leadId
  );
};

export const APIgetProposalDetailForAdmin = (leadId) => {
  return privateAPIClient.get(
    LeadURLStrings.proposalDetailsForAdmin + "/" + leadId
  );
};

export const APIgetSoldPolicyDetailForAdmin = (leadId) => {
  return privateAPIClient.get(
    LeadURLStrings.soldPolicyDetailsForAdmin + "/" + leadId
  );
};

// use for Quotation,Praposal,Sold details
export const APIgetCommonDetails = (id, payload) => {
  return privateAPIClient.get(
    LeadURLStrings.getCommonDetails + "/" + id + "/" + payload?.flag
  );
};

//sold Policy Download
export const APISoldPolicyDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.downloadSoldPolicy, payload);
};

export const APIgetMDSList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getDMSList, payload);
};
//DMS
export const APICreateDocument = (payload) => {
  return privateAPIClient.post(LeadURLStrings.createdocument, payload);
};

export const APIgetDocumentUploadList = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.getDocumentUploadStatusList + "/" + id
  );
};

export const APIdeleteDocumentData = (payload) => {
  return privateAPIClient.post(LeadURLStrings.DocumentDelete, payload);
};

export const APIgetDocumentGroupList = () => {
  return privateAPIClient.get(LeadURLStrings.getdocumentdropdownlist);
};

export const APIgetDocumentGrouptypeList = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.getdocumentdropdowntype + "/" + id
  );
};
export const APIdmsDetails = (payload) => {
  return privateAPIClient.get(LeadURLStrings.dmsDetails + "/" + payload?.id);
};

export const APIDocDownload = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.downloadLeadDoc + "/" + payload?.filePath
  );
};

// export const APIcreateRole = (payload) => {
//   return apiClient.post(APIUrlStrings.createRole, payload);
// };

// ----------ALL lead List------
export const APIgetLeadTaskList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadList, payload);
};

export const APIgetLeadDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadDownload, payload);
};
export const APIcreateLeadTaskList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadCreate, payload);
};
//pending
export const APIgetByIDLeadTaskList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadCreate, payload);
};
export const APIupdateLeadTaskData = (payload) => {
  return privateAPIClient.put(LeadURLStrings.leadUpdate, payload);
};
export const APIdeleteLeadTaskData = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadDelete, payload);
};
export const APILeadAssign = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadAssign, payload);
};

export const APILeadTransfer = (payload) => {
  return privateAPIClient.post(LeadURLStrings.leadTransfer, payload);
};

export const APILeadCountData = () => {
  return privateAPIClient.get(LeadURLStrings.leadCount);
};

export const APILeadEdit = (id, payload) => {
  return privateAPIClient.post(LeadURLStrings.leadEdit + "/" + id, payload);
};

// --------- Lead- Dropdowns -------
export const APIgetQuotFromGiib = (payload) => {
  return privateAPIClient.post(LeadURLStrings.quoteFromGiib, payload);
};
export const APIgetICList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.icList, payload);
};

// ----------Quote date send to BE-------

export const APIsendQuoteDataToBE = (payload) => {
  return privateAPIClient.post(LeadURLStrings.sendQuoteData, payload);
};

export const APIgetLeadPolicyTypeDropDown = () => {
  return privateAPIClient.get(LeadURLStrings.policyTypeDropdown);
};
export const APIgetTeamLeaderDropdown = () => {
  return privateAPIClient.get(LeadURLStrings.teamLeaderDropdown);
};
export const APIgetTelecallerDropdown = (payload) => {
  return privateAPIClient.get(LeadURLStrings.telecallerDropdown +"?telecallerId="+payload?.telecallerId);
};
export const APIgetAssignedTelecaller = (id) => {
  return privateAPIClient.get(LeadURLStrings.telecallerDropdown + "?id=" + id);
};
export const APIgetLeadTypeDropDown = (paylaod) => {
  return privateAPIClient.get(
    LeadURLStrings.leadTypeDropdown + "?status=" + paylaod?.status +"&id="+paylaod?.id
  );
};
export const APIgetLeadPlanTypeDropDown = () => {
  return privateAPIClient.get(LeadURLStrings.leadPlanTypeDropdown);
};
export const APIgetLeadSourceDropDown = () => {
  return privateAPIClient.get(LeadURLStrings.leadSourceDropdown);
};

export const APIgetProductTypeDropDown = () => {
  return privateAPIClient.get(LeadURLStrings.productDropdown);
};
// Lost Leads
export const APIgetLostLeads = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getLostLeadList, payload);
};
export const APILeadReAssign = (payload) => {
  return privateAPIClient.post(LeadURLStrings.reAssignLostlead, payload);
};
export const APIgetLostLeadTelecallerDropdown = () => {
  return privateAPIClient.get(LeadURLStrings.getLostLeaadTelecaller);
};
export const APILostLeadReAssign = (payload) => {
  return privateAPIClient.post(LeadURLStrings.postLostLeadReAssign, payload);
};

// Telecaller lead
export const APItelecallerLeadList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.telecallerLeadList, payload);
};

// ----- user and role ----

export const APIcountRoleUserTeam = () => {
  return authClient.get(AuthURLStrings.countRoleUserTeam);
};
export const APIgetRole = () => {
  return authClient.get(AuthURLStrings.getRole);
};
export const APIUpdatePermission = (payload) => {
  return authClient.post(AuthURLStrings.updatePermission, payload);
};
export const APIteamName = (id) => {
  return authClient.get(AuthURLStrings.teamName + "/" + id);
};
export const APItelecallerListing = (payload) => {
  return authClient.post(AuthURLStrings.telecallerListing, payload);
};
export const APIgetTeamLeader = () => {
  return authClient.get(AuthURLStrings.getTeamLeader);
};
export const APIgetAllTeam = () => {
  return authClient.get(AuthURLStrings.getAllTeam);
};
export const APIgetProduct = () => {
  return authClient.get(AuthURLStrings.getProduct);
};
export const APIroleDropdownList = () => {
  return authClient.get(AuthURLStrings.roleDropdownList);
};
export const APIdropdownReportingPerson = (id) => {
  return authClient.get(AuthURLStrings.dropdownReportingPerson + "/" + id);
};
export const APIdeleteRole = (id) => {
  return authClient.put(AuthURLStrings.deleteRole + "/" + id);
};
export const APIdeleteTeam = (id) => {
  return authClient.put(AuthURLStrings.deleteTeam + "/" + id);
};
export const APIUserDelete = (id) => {
  return authClient.patch(AuthURLStrings.userDelete + "/" + id);
};
export const APIUserGetById = (payload) => {
  return authClient.get(AuthURLStrings.userGetById + "/" + payload?.userID);
};
export const APIUserUpdate = (payload) => {
  return authClient.post(AuthURLStrings.userUpdate, payload);
};
export const APIRoleUpdate = (payload) => {
  return authClient.post(AuthURLStrings.roleUpdate, payload);
};
export const APITeamUpdate = (id, payload) => {
  return authClient.put(AuthURLStrings.teamUpdate + "/" + id, payload);
};
export const APICreateUser = (payload) => {
  return authClient.post(AuthURLStrings.userCreate, payload);
};
export const APICreateTeam = (payload) => {
  return authClient.post(AuthURLStrings.createTeam, payload);
};
export const APICreateRole = (payload) => {
  return authClient.post(AuthURLStrings.createRole, payload);
};

// -------- Alternate Number --------
export const APIAlternateNumber = (leadId, payload) => {
  return privateAPIClient.post(
    LeadURLStrings.alternateNumber + "/" + leadId,
    payload
  );
};

// -------- Telecaller Disposition --------

export const APIgetDispositionLead = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.getDispositionLeadById + "/" + payload?.Id
  );
};
export const APILeadCountTelecallerWise = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.LeadCountTelecallerWise + "/" + id
  );
};

export const APIgetDispositionLeadDetails = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.getDispositionLeadDetailsById + "/" + id
  );
};
export const APIgetLeadStatusUpdate = (id) => {
  return privateAPIClient.get(LeadURLStrings.getStatusUpdate + "/" + id);
};

export const APIgetDispositionDropdown = (id) => {
  return privateAPIClient.get(LeadURLStrings.getDispositionDropDown);
};

export const APIgetSubDispositionDropdown = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.getSubDispositionDropdown + "/" + id
  );
};

export const APIgetleadDetailsForTelecaller = (id) => {
  return privateAPIClient.post(LeadURLStrings.leadDetailsForTelecaller);
};

// Auditor
export const APIgetAuditorList = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getAuditorList, payload);
};
export const APIgetCallRecordingDetails = (id) => {
  return privateAPIClient.get(
    LeadURLStrings.getCallRecordingDetails + "/" + id
  );
};

export const APIgetAuditDetail = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.getAuditDetails + "/" + payload?.id
  );
};

export const APICallRating = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getCallRating, payload);
};

// upload data
export const APIBulkUpload = (formData, payload) => {
  return privateAPIClient.post(
    LeadURLStrings.bulkUplodFile + "/" + payload,
    formData
  );
};

// fetch leads
export const APIFetchLeads = (payload) => {
  return privateAPIClient.post(LeadURLStrings.fetchLeads, payload);
};

export const APIMasterDictionary = (payload) => {
  return privateAPIClient.post(LeadURLStrings.materDataDictionary, payload);
};

export const APIDownloadInvalidData = (payload) => {
  return privateAPIClient.get(LeadURLStrings.downloadInvalidData, payload);
};

export const APIFormetDownload = () => {
  return privateAPIClient.get(LeadURLStrings.formetDownload);
};

export const APIgetInvalidData = (payload) => {
  return privateAPIClient.post(LeadURLStrings.invalidData, payload);
};

//Quotation
export const APIgetQuotation = (payload) => {
  return privateAPIClient.post(LeadURLStrings.getQuotationDetails);
};

// Disposition
export const addDisposition = (payload) => {
  return privateAPIClient.post(LeadURLStrings.createDisposition, payload);
};

// -------Initiate call API------Edit Lead Information

export const APIDialler = (leadID, payload) => {
  return privateAPIClient.post(LeadURLStrings.dialler + "/" + leadID, payload);
};

// ---------call drop API-----
export const APIcallDrop = (payload ) => {
  return privateAPIClient.post(LeadURLStrings.dropCall + "/" + payload?.leadId,);
};

export const APISendEmail = (payload, leadID) => {
  return privateAPIClient.post(
    LeadURLStrings.sendEmail ,
    payload
  );
};
export const APISendSMS = (payload) => {
  return privateAPIClient.post(LeadURLStrings.sendSMS, payload);
};

export const APIPolicyStatus = (leadId) => {
  return privateAPIClient.get(LeadURLStrings.policyStatus + "/" + leadId);
};

// Admin Dashbord APi
// LeadListing
export const APIgetProductCards = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.productleadList + "?timeRange=" + payload?.timeRange
  );
};

// APIAdminFollowUpLeads

export const APIAdminFollowUpLeads = (payload) => {
  return privateAPIClient.post(LeadURLStrings.adminFollowupData, payload);
};

// //Top Plan
export const APIgetTopPlans = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.planList + "?timeRange=" + payload?.timeRange
  );
};

export const APILeadStatistics = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.leadStatistic +
      "?timeRange=" +
      payload?.timeRange +
      "&" +
      "policyType=" +
      payload?.policyType
  );
};

export const APIProductPerformance = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.productPerformance +
      "?timeRange=" +
      payload?.timeRange +
      "&" +
      "planType=" +
      payload?.planType
  );
};
export const APIMISreportDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.misReportDownload, payload);
};

export const APIMISAllreportDownload = (payload) => {
  return privateAPIClient.post(LeadURLStrings.misAllReportDownload, payload);
};

export const APILeadSource = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.leadSource + "?timeRange=" + payload?.timeRange
  );
};

// Telecaller Dashboard API
export const APITelecAllerCardData = (payload) => {
  return privateAPIClient.get(LeadURLStrings.tellercallerdashboardCardData);
};
export const APIPolicyStatsComponent = (payload) => {
  return privateAPIClient.post(LeadURLStrings.PolicyStatsComponent, payload);
};

export const APIPolicyAnalysisComponent = (payload) => {
  return privateAPIClient.post(LeadURLStrings.PolicyAnalysisComponent, payload);
};

export const APITelecAllerActionableInsights = (payload) => {
  return privateAPIClient.post(LeadURLStrings.actionableInsights);
};
export const APITelecallerLeadInformation = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.leadInformation + "/" + payload?.leadId
  );
};

export const APITopPerformance = (payload) => {
  return privateAPIClient.get(
    LeadURLStrings.topPerformance +
      "?timeRange=" +
      payload?.timeRange +
      "&page=" +
      payload?.page +
      "&pageSize=" +
      payload?.pageSize
  );
};

//Lead Deviation
export const APIIDVCountData = () => {
  return privateAPIClient.get(LeadURLStrings.idvCount);
};
export const APIDiscountCountData = () => {
  return privateAPIClient.get(LeadURLStrings.discountCount);
};
export const APIBreakinCountData = () => {
  return privateAPIClient.get(LeadURLStrings.breakinCount);
};

// export const APIgetByIdLostLeads = (payload) => {
//   return privateAPIClient.get(LeadURLStrings.getLostLeadList, payload);
// };
// export const APIdeleteLostLeads = (payload) => {
//   return privateAPIClient.post(LeadURLStrings.getLostLeadList, payload);
// // };
// export const APIupdateLostLeads = (payload) => {
//   return privateAPIClient.post(LeadURLStrings.getLostLeadList, payload);
// };

// export const APIgetRoleList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.roles +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateRole = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateRole, payload);
// };

// export const APICreateRoles = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createRole, payload);
// };

// export const APIDeleteRoles = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteRole, payload);
// };

// export const APIgetPermissionsList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.permissions +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdatePermission = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updatePermission, payload);
// };

// export const APIDeletePermission = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deletePermission, payload);
// };

// export const APICreatePermissions = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createPermission, payload);
// };

// export const APICreateRolesPermissions = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.createRolesPermission,
//     payload
//   );
// };

// export const APIRoleWisePermissionList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.roleWisePermission, payload);
// };

// export const APIgetPincodeList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.pincodes +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APICreatePincode = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createPincode, payload);
// };

// export const APIUpdatePincode = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updatePincode, payload);
// };

// export const APIDeletePincode = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deletePincode, payload);
// };

// export const APIgetTimeSlotList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.timeSlots +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIgetDeliveryTimeSlotDayWise = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.deliveryTimeSlotDayWise +
//       "?pincode=" +
//       payload?.pincode +
//       "&delivery_type_id=" +
//       payload?.delivery_type_id +
//       "&days_id=" +
//       payload?.days_id
//   );
// };

// export const APIUpdateTimeSlot = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateTimeSlot, payload);
// };

// export const APIDeleteTimeSlot = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteTimeSlot, payload);
// };

// export const APITimeSlotEditList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.TimeSlotedit, payload);
// };

// export const APICreateTimeSlot = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createTimeSlot, payload);
// };

// export const APIgetCustomerInfoList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.customerList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIgetCustomerAddressList = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.customerAddressList, payload);
// };

// export const APIgetAddCustomerAddress = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createCustomerAddress, payload);
// };

// export const APIAddToCartProduct = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.addToCartProduct, payload);
// };

// export const APIDecreaseProductQty = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.cartProductQtyDecrement,
//     payload
//   );
// };

// export const APIIncreaseProductQty = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.cartProductQtyIncrement,
//     payload
//   );
// };

// export const APIgetCartProductList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.cartProductList, payload);
// };

// export const APIgetRemoveCartProduct = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.removeCartProduct, payload);
// };

// export const APIgetStateList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.stateList + "?search_text=" + payload?.search_text
//   );
// };

// export const APIgetCityList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.cityList +
//       "?search_text=" +
//       payload?.search_text +
//       "&id=" +
//       payload?.id
//   );
// };

// export const APIgetDeliveryTypeList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.deliveryTypes +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "") +
//       "&is_status=" +
//       (payload?.is_status === 1 ? 1 : payload?.is_status === 0 ? 0 : "")
//   );
// };

// export const APIUpdateDeliveryType = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateDeliveryType, payload);
// };

// export const APIDeleteDeliveryType = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteDeliveryType, payload);
// };

// export const APICreateDeliveryType = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.createDeliveryType,
//     payload
//   );
// };

// export const APIgetMainStoreList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.mainStoreList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateMainStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateMainStore, payload);
// };

// export const APIDeleteMainStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteMainStore, payload);
// };

// export const APICreateMainStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createMainStore, payload);
// };

// export const APIgetSubStoreList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.subStoreList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateSubStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateSubStore, payload);
// };

// export const APIDeleteSubStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteSubStore, payload);
// };

// export const APICreateSubStore = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createSubStore, payload);
// };

// export const APISubStoreIsSubStoresList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.subStoreIsSubStoresList, payload);
// };

// export const APISubStoreEditList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.subStoreEditList, payload);
// };

// export const APIgetCategoryEdit = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editCategoryList, payload);
// };

// export const APIgetMainCategoryList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.mainCategoryList, payload);
// };

// export const APIgetAllCategoryList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.allCategoryList, payload);
// };

// export const APIgetSubCategoryList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.subCategoryList, payload);
// };

// export const APIgetCategoryStatusUpdate = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.statusUpdateCategory, payload);
// };

// export const APICreateCategory = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createCategory, payload);
// };

// export const APIUpdateCategory = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.updateCategory, payload);
// };

// export const APIgetCollectionList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.collectionsList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateCollection = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.updateCollection,
//     payload
//   );
// };

// export const APIDeleteCollection = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteCollection, payload);
// };

// export const APIStatusUpdateCollection = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.statusUpdateCollection, payload);
// };

// export const APIEditCollection = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editCollection, payload);
// };

// export const APICreateCollection = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.createCollection,
//     payload
//   );
// };

// export const APIgetUsersList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.usersList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateUsers = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.updateUsers, payload);
// };
// export const APIUpdateStatusUsers = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateStatusUsers, payload);
// };

// export const APIDeleteUsers = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteUsers, payload);
// };

// export const APICreateUsers = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createUsers, payload);
// };
// export const APIEditUsers = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editUsers, payload);
// };

// export const APIgetBrandsList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.brandsList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateBrands = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.updateBrands, payload);
// };
// export const APIStatusUpdateBrands = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.statusUpdateBrands, payload);
// };

// export const APIDeleteBrands = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteBrands, payload);
// };

// export const APICreateBrands = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createBrands, payload);
// };
// export const APIEditBrands = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editBrands, payload);
// };

// export const APIgetBannerList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.bannerList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIUpdateBanner = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.updateBanner, payload);
// };
// export const APIStatusUpdateBanner = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.statusUpdateBanner, payload);
// };

// export const APIDeleteBanner = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteBanner, payload);
// };

// export const APICreateBanner = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createBanner, payload);
// };
// export const APIEditBanner = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editBanner, payload);
// };

// export const APIgetProductList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.productList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APIgetPincodeWiseProductsList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.pincodeWiseProducts +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "") +
//       "&pincode=" +
//       (payload?.pincode || "")
//   );
// };

// export const APIUpdateProduct = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.updateProduct, payload);
// };
// export const APIStatusUpdateProduct = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.statusUpdateProduct, payload);
// };

// export const APIDeleteProduct = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteProduct, payload);
// };

// export const APICreateProduct = (payload) => {
//   return privateAPIClientMultiForm.post(APIUrlStrings.createProduct, payload);
// };

// export const APIEditProduct = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editProduct, payload);
// };

// export const APIAddonProductList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.addonProduct, payload);
// };

// export const APIgetOrderList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.orderList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "") +
//       "&ordersBy=" +
//       (payload?.ordersBy || "") +
//       "&order_status_id=" +
//       (payload?.order_status_id || "")
//   );
// };

// export const APIEditOrdersProduct = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.orderProducts, payload);
// };

// export const APIOrderProductsOrderPlace = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.orderProductsOrderPlace, payload);
// };

// // export const getProducts = (
// //   searchParam = "",
// //   pageNumber = 1,
// //   rowsPerPage = 10,
// //   sortBy = "id-desc"
// // ) => {
// //   return privateAPIClient.get(
// //     APIUrlStrings.getProducts +
// //       `?page=${pageNumber}&per_page=${rowsPerPage}&sort_by=${sortBy}&search=${searchParam}`
// //   );
// // };

// export const getDashboardData = () => {
//   return privateAPIClient.get(APIUrlStrings.roles);
// };

// export const APICreateCoupon = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.createCoupon, payload);
// };

// export const APIUpdateCoupon = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateCoupon, payload);
// };

// export const APICouponCodeList = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.couponCodeList, payload);
// };

// export const APIStatusUpdateCouponCode = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.updateStatusCouponCode, payload);
// };

// export const APIDeleteCouponCode = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteCouponCode, payload);
// };

// export const APIEditCouponCode = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editCouponCode, payload);
// };

// export const APIgetShipmentList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.shipmentList +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };

// export const APICreateShipment = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.shipmentCreate, payload);
// };

// export const APIDeleteShipment = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.shipmentDelete, payload);
// };

// export const APIEditShipment = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.shipmentEdit, payload);
// };

// export const APIUpdateShipment = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.shipmentUpdate, payload);
// };

// export const APIUpdateConfigration = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.updateConfigration,
//     payload
//   );
// };

// export const APIDeleteConfigration = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.deleteConfigration, payload);
// };

// export const APICreateConfigration = (payload) => {
//   return privateAPIClientMultiForm.post(
//     APIUrlStrings.createConfiration,
//     payload
//   );
// };

// export const APIConfigrationGroupList = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.ConfigrationGrouplist, payload);
// };

// export const APIEditCongigration = (payload) => {
//   return privateAPIClient.get(APIUrlStrings.editConfigration, payload);
// };

// // export const APICongigrationList = (payload) => {
// //   return privateAPIClient.get(APIUrlStrings.ConfigrationList, payload);
// // };

// export const APICongigrationList = (payload) => {
//   return privateAPIClient.post(
//     APIUrlStrings.listConfigration +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };
