// saveFormatDate.jsx
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentScreen:{
      leadtotal:{
        startValue:null,
        endValue:null,
        activeTab:null,
        searchTerm:null,
        selectedTellecaller:null,
      },
      followUpModal:{
        startValue:null,
        endValue:null,
        modalOpen:null,
        LeadPageOpenfromFollowUp:null,
      }
    }
};

const saveFormatDateSlice = createSlice({
  name: 'saveFormatDate',
  initialState,
  reducers: {
    setsaveFormatDate: (state, action) => {
      state.currentScreen.leadtotal.startValue = action.payload;
    },
    setEndFormatDate: (state, action) => {
      state.currentScreen.leadtotal.endValue = action.payload;
    },
    setReduxActiveTab: (state, action) => {
      state.currentScreen.leadtotal.activeTab = action.payload;
    },
    setSearchTermInActiveTab: (state, action) => {
      state.currentScreen.leadtotal.searchTerm = action.payload;
    },
    setStartFollowUpFormatDate: (state, action) => {
      state.currentScreen.followUpModal.startValue = action.payload;
    },
    setEndFollowUpFormatDate: (state, action) => {
      state.currentScreen.followUpModal.endValue = action.payload;
    },
    setFollowUpModalOpen: (state, action) => {
      state.currentScreen.followUpModal.modalOpen = action.payload;
    },
    setLeadPageOpenfromFollowUp: (state, action) => {
      state.currentScreen.followUpModal.LeadPageOpenfromFollowUp = action.payload;
    },
    setSelectedTellecaller: (state, action) => {
      state.currentScreen.leadtotal.selectedTellecaller = action.payload;
    },
  }
});

export const { setsaveFormatDate, setEndFormatDate, setReduxActiveTab, setSearchTermInActiveTab, setStartFollowUpFormatDate, setEndFollowUpFormatDate, setFollowUpModalOpen, setLeadPageOpenfromFollowUp, setSelectedTellecaller } = saveFormatDateSlice.actions;
export default saveFormatDateSlice.reducer;
